import React from "react";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { PrivacyPolicyQuery } from "../../prismic/staticQueries";
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CountUp from "react-countup";
import banner_image from "../../images/siteImages/privacypolicy/Privacy-Policy-Security.jpg";
import PaymentSlider from "./PaymentSlider";


const PrivacyPolicy = () => {
  const language = useSelector((state) => state.language);
  console.log("language", language);
  const privacyPolicyData = PrivacyPolicyQuery(language);
  console.log(".....", privacyPolicyData);
  // // const { seo_page_title, seo_page_description, seo_page_keywords } =
  // //   privacyPolicyData;
  const {
    privacy_policy_heading,
    privacy_policy_heading1,
    privacy_policy_heading2,
    privacy_policy_heading3,
    privacy_policy_heading4,
    privacy_policy_heading5,
    privacy_policy_heading6,
    privacy_policy_heading7,
    privacy_policy_body,
    privacy_policy_body1,
    privacy_policy_body2,
    privacy_policy_body3,
    privacy_policy_body4,
    privacy_policy_body5,
    privacy_policy_body6,
    privacy_policy_body7,
    privacy_policy_body8,
    privacy_policy_body9,
    privacy_policy_body10,
    privacy_policy_body11,
    privacy_policy_body12,
    privacy_policy_body13,
    privacy_policy_body14,
    privacy_policy_body15,
    privacy_policy_body16,
    privacy_policy_body17,
    privacy_policy_body18,
    privacy_policy_body19,
    privacy_policy_body20,
    privacy_policy_body21,
    privacy_policy_body22,
    privacy_policy_body23,
    privacy_policy_body24,
    privacy_policy_body25,
    privacy_policy_body26,
    privacy_policy_body27,
    privacy_policy_list,
    privacy_policy_list1,
    privacy_policy_list2,
    privacy_policy_list3,
    privacy_policy_list4,
    privacy_policy_list5,
    privacy_policy_list6,
    privacy_policy_list7,
    privacy_policy_list8,
    privacy_policy_list9,
    privacy_policy_list10,
    privacy_policy_list11,
    privacy_policy_list12,
  } = privacyPolicyData;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (

    <Layout >
      <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg p-top-100 p-bottom-110 faqs">
        <Container className="">
          <Row>
            <Col lg={12}>
              <h4 className="pt-5 my-3 text-bold-md text-black">{privacy_policy_heading.text}</h4>
              <p className="text-gray my-3">{privacy_policy_body.text}</p>
              <p className="text-gray my-3">{privacy_policy_body1.text}</p>
              <p className="text-gray my-3">{privacy_policy_body2.text}</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">{privacy_policy_heading1.text}</h4>
              <p className="text-gray my-3">{privacy_policy_body3.text}</p>
              <p className="text-gray my-3">{privacy_policy_body4.text}</p>
              <ul className="privacy-list">
                <li className="text-gray my-3">{privacy_policy_list.text}</li>
                <li className="text-gray my-3">{privacy_policy_list1.text}</li>
              </ul>
              <p className="text-gray my-3">{privacy_policy_body5.text}</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">{privacy_policy_heading2.text}</h4>
              <p className="text-gray my-3">{privacy_policy_body6.text}</p>              
              <p className="text-gray my-3">{privacy_policy_body7.text}</p>
              <ul className="privacy-list">
                <li className="text-gray my-3">{privacy_policy_list2.text}</li>
                <li className="text-gray my-3">{privacy_policy_list3.text}</li>
              </ul>
              <p className="text-gray my-3">{privacy_policy_body8.text}</p>
              <p className="text-gray my-3">{privacy_policy_body9.text}</p>
              <p className="text-gray my-3">{privacy_policy_body10.text}</p>
              <p className="text-gray my-3">{privacy_policy_body11.text}</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">{privacy_policy_heading3.text}</h4>
              <p className="text-gray my-3">{privacy_policy_body12.text}</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">{privacy_policy_heading4.text}</h4>
              <p className="text-gray my-3">{privacy_policy_body13.text}</p>
              <p className="text-gray my-3">{privacy_policy_body14.text}</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">{privacy_policy_heading5.text}</h4>
              <p className="text-gray my-3">{privacy_policy_body15.text}</p>
              <p className="text-gray my-3">{privacy_policy_body16.text}</p>
              <p className="text-gray my-3">{privacy_policy_body17.text}</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">{privacy_policy_heading6.text}</h4>
              <p className="text-gray my-3">{privacy_policy_body18.text}</p>
              <p className="text-gray my-3">{privacy_policy_body19.text}</p>
              <p className="text-gray my-3">{privacy_policy_body20.text}</p>
              <p className="text-gray my-3">{privacy_policy_body21.text}</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">{privacy_policy_heading7.text}</h4>
              <p className="text-gray my-3">{privacy_policy_body22.text}</p>
              <ul className="privacy-list">
              <li className="text-gray my-3">{privacy_policy_list4.text}</li>
                <li className="text-gray my-3">{privacy_policy_list5.text}</li>
                <li className="text-gray my-3">{privacy_policy_list6.text}</li>
                <li className="text-gray my-3">{privacy_policy_list7.text}</li>
                <li className="text-gray my-3">{privacy_policy_list8.text}</li>
                <li className="text-gray my-3">{privacy_policy_list9.text}</li>
                <li className="text-gray my-3">{privacy_policy_list10.text}</li>
                <li className="text-gray my-3">{privacy_policy_list11.text}</li>
                <li className="text-gray my-3">{privacy_policy_list12.text}</li>
              </ul>
              <p className="text-gray my-3">{privacy_policy_body23.text}</p>
              <p className="text-gray my-3">{privacy_policy_body24.text}</p>
              <p className="text-gray my-3">{privacy_policy_body25.text}</p>
              <p className="text-gray my-3">{privacy_policy_body26.text}</p>
              <p className="text-gray my-3">{privacy_policy_body27.text}</p>
            </Col>
          </Row>
        </Container>
      </section>

      
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(PrivacyPolicy);
